import { createApp } from 'vue'
import App from './App.vue'
window.$ = window.jQuery = require('jquery');
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import lazyPlugin from 'vue3-lazy'
import Vue3Storage from "vue3-storage";
import { VueCookieNext } from 'vue-cookie-next'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import vueTopprogress from 'vue-top-progress'

// axios.defaults.baseURL = 'http://196.202.135.251/telesisAPI/APIs/'
const loadimage = require('./assets/Logo1.png')
const app = createApp(App);
   app.use(router)
   router.app = app
   app.use(VueAxios, axios)
   app.use(lazyPlugin, {
      preLoad: 1.3,
      loading: loadimage,
      attempt: 1
   })
   app.use(Vue3Storage, { namespace: "_get" })
   app.use(VueCookieNext)
   // app.use(Toast, {
   //    position: POSITION.BOTTOM_RIGHT
   // })
   app.use(Toast, {
      transition: "Vue-Toastification__fade",
      maxToasts: 30,
      newestOnTop: true,
      timeout: 4000,
                closeOnClick: false,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: false,
                icon: true,
                rtl: true
    })
   app.use(vueTopprogress)
   app.mount('#app')



