import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}
function lazyPage(view){
  return() => import(`@/views/Page/${view}.vue`)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: lazyLoad('Home')
  },
  {
    path: '/game',
    name: 'Game',
    component: () => import(/* webpackChunkName: "game" */ '../views/Page/Game.vue')
  },
  {
    path: '/islamyat',
    name: 'Islamyat',
    component: lazyPage('Islamyat')
 },
  {
    path: '/kitchen',
    name: 'Kitchen',
    component: lazyPage('Kitchen')
  },
  {
    path: '/kids',
    name: 'Kids',
    component: lazyPage('Kids')  },
  {
    path: '/sound/:id',
    name: 'Sound',
    component: lazyPage('Sound'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/content/:id',
    name: 'Content',
    component: lazyPage('Content'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/gameDetail/:id',
    name: 'GameDetail',
    component: lazyPage('GameDetail'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/play/:id',
    name: 'Play',
    component: lazyPage('Play'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/search/:search_id',
    name: 'Search',
    component: lazyPage('Search'),
    props: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/User/Login.vue'),
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import(/* webpackChunkName: "subscribe" */ '../views/User/Subscribe.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/User/Unsubscribe.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/Page/NotFound.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})


router.beforeEach((to, from, next ) => {
  const { $cookie } = router.app.config.globalProperties
   if(to.meta.IsAuth) {
    if($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
      next();
    } else {
      next(window.open("https://dsplp.sd.zain.com/?p=2887461945", "_self"));
    }
   } else {
     next();
   }
})

export default router
